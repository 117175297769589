<template>
  <main class="container mx-auto">
    <div class="md:flex hidden items-center justify-between w-full gap-x-16" :class="reverse ? 'flex-row-reverse' : ''">
      <!-- Content Section -->
      <div class="w-[600px] flex flex-col">
        <!-- Tabs Container -->
        <div class="w-full">
          <div class="relative" v-for="(item, index) in details" :key="index + item.id">
            <div class="flex items-center">
              <div
                class="w-2 h-12 rounded-full transition-colors duration-500 mr-6"
                :class="activeItem.id === item.id ? 'bg-brand-black' : 'bg-transparent'"
              ></div>
              <p
                class="text-[32px] transition-colors duration-500"
                :class="activeItem.id === item.id ? 'text-[#1A1A1A] font-medium' : 'text-[#94A3B8]'"
              >
                {{ item.text }}
              </p>
            </div>
            <hr class="my-6 border-gray-200 ml-8" />
          </div>
        </div>

        <!-- Content Container -->
        <div class="w-full flex-1">
          <transition name="fade" mode="out-in">
            <div :key="activeItem.id" class="w-full">
              <div class="ml-8">
                <p class="text-[#475569] text-lg font-normal mb-8 leading-relaxed">
                  {{ activeItem.description }}
                </p>
                <a
                  :href="activeItem.link"
                  class="px-8 py-4 text-white inline-block rounded-xl bg-brand-black hover:bg-black transition-colors duration-300 font-semibold"
                  >{{ activeItem.btnText }}</a
                >
              </div>
            </div>
          </transition>
        </div>
      </div>

      <!-- Image Section -->
      <div class="w-[600px] flex items-center">
        <div class="w-full relative" style="height: 600px">
          <transition name="fade" mode="out-in">
            <div :key="activeItem.id" class="absolute inset-0 flex items-center justify-center">
              <img :src="activeItem.imgSrc" :alt="activeItem.text" class="max-w-full max-h-full object-contain" />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <!-- Mobile View -->
    <div v-for="item in details" :key="item.id" class="pb-16 md:hidden">
      <div class="flex items-center">
        <div class="w-2 h-12 rounded-full mr-6 bg-brand-black"></div>
        <p class="text-3xl text-[#1A1A1A] font-medium">
          {{ item.text }}
        </p>
      </div>
      <img :src="getImageSrc(item.img)" :alt="item.text" class="my-6 w-full" loading="lazy" />
      <div class="flex flex-col">
        <p class="text-[#475569] text-lg font-normal mb-8 leading-relaxed">{{ item.description }}</p>
        <a
          :href="item.link"
          class="px-8 py-4 text-white inline-block rounded-xl bg-brand-black hover:bg-black transition-colors duration-300 font-semibold text-center"
          >{{ item.btnText }}</a
        >
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const props = defineProps({
  details: {
    type: Array,
    required: true,
    validator: (value) => value.length > 0,
  },
  reverse: {
    type: Boolean,
    default: false,
  },
});

const current = ref(1);
let intervalId;

// Pre-compute image sources
const imageSources = computed(() => {
  return props.details.reduce((acc, item) => {
    acc[item.id] = require(`@/assets/images/${item.img}`);
    return acc;
  }, {});
});

// Computed active item to avoid repeated find operations
const activeItem = computed(() => {
  const item = props.details.find((item) => item.id === current.value) || props.details[0];
  return {
    ...item,
    imgSrc: imageSources.value[item.id],
  };
});

// Cache image sources for better performance
const getImageSrc = (img) => require(`@/assets/images/${img}`);

const switchSlide = () => {
  current.value = current.value >= props.details.length ? 1 : current.value + 1;
};

// Pause animation when tab is not visible
const handleVisibilityChange = () => {
  if (document.hidden) {
    clearInterval(intervalId);
  } else {
    intervalId = setInterval(switchSlide, 5000);
  }
};

onMounted(() => {
  intervalId = setInterval(switchSlide, 5000);
  document.addEventListener('visibilitychange', handleVisibilityChange);
});

onUnmounted(() => {
  clearInterval(intervalId);
  document.removeEventListener('visibilitychange', handleVisibilityChange);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
