<template>
  <section class="flex justify-center bg-[#FEFAF1] md:px-20 px-6 py-[60px]">
    <div class="w-full lg:w-[41vw]">
      <img :src="peopleIcon" alt="People connecting" class="mx-auto w-1/2" loading="eager" width="300" height="200" fetchpriority="high" />
      <h2 class="md:text-[44px] text-[32px] text-brand-black py-6 text-center">Where high growth companies and exceptional people connect</h2>
      <p class="text-black font-normal text-base pb-6 text-center">
        Looking for a new role? Create a profile on CareerBuddy to connect directly with hiring managers. Hiring? We're the go-to platform for
        sourcing the best tech and operations talent out there.
      </p>
      <div class="flex flex-col md:flex-row md:items-center gap-4 md:justify-center">
        <a
          href="/employer"
          class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white text-center hover:bg-black transition-colors duration-300"
        >
          Find your next hire
        </a>
        <a
          href="/jobs"
          class="px-6 py-3.5 text-brand-black inline-block rounded-xl border border-brand-black text-center hover:bg-gray-50 transition-colors duration-300"
        >
          Find your next job
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
// Only import the icon that's actually being used
import { computed } from 'vue';

// Pre-load and cache the image
const peopleIcon = computed(() => require('@/assets/images/people.svg'));
</script>

<style scoped>
/* Use hardware acceleration for transitions */
.transition-colors {
  will-change: background-color;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
</style>
