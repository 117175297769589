<template>
  <section class="md:w-[78vw] w-full px-4 md:px-0 m-auto" aria-label="Testimonials">
    <h2 class="md:text-center text-[20px] md:text-[30px] text-brand-black md:leading-[48px] md:font-semibold">Hear what others are saying</h2>

    <div class="reviews-container">
      <nav 
        class="reviews-tabs" 
        role="tablist" 
        aria-label="Reviews sections"
      >
        <button 
          @click="switchTab(1)" 
          class="tab-button" 
          :class="activeTabClasses(1)" 
          role="tab"
          :aria-selected="current === 1"
          id="client-tab"
          aria-controls="client-panel"
          tabindex="0"
          @keydown.space.prevent="switchTab(1)"
          @keydown.enter.prevent="switchTab(1)"
        >
          <span class="tab-text">Client Reviews</span>
        </button>

        <button 
          @click="switchTab(2)" 
          class="tab-button" 
          :class="activeTabClasses(2)" 
          role="tab"
          :aria-selected="current === 2"
          id="career-tab"
          aria-controls="career-panel"
          tabindex="0"
          @keydown.space.prevent="switchTab(2)"
          @keydown.enter.prevent="switchTab(2)"
        >
          <span class="tab-text">Career Series Reviews</span>
        </button>

        <div
          class="tab-indicator"
          :class="indicatorPosition"
          aria-hidden="true"
        ></div>
      </nav>
    </div>

    <TransitionGroup name="fade" tag="div" class="reviews-grid" :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
      <template v-if="current === 1">
        <article v-for="review in activeReviews" :key="`client-${review.id}`" class="testimonial-card">
          <div class="flex md:items-center justify-between flex-col md:flex-row mb-9">
            <div class="order-2 md:order-1">
              <h3 class="font-bold text-base pb-0">{{ review.name }}</h3>
              <small class="text-xs text-[#666666]">{{ review.title }}</small>
            </div>
            <img :src="review.logo" :alt="`${review.name}'s company logo`" class="company-logo" loading="lazy" />
          </div>
          <p class="text-brand-black font-normal md:text-lg text-base flex-grow whitespace-pre-line">{{ review.content }}</p>
        </article>
      </template>
      <template v-else>
        <article v-for="review in activeReviews" :key="`career-${review.id}`" class="testimonial-card">
          <div class="flex items-start justify-between flex-col mb-6">
            <h3 class="font-bold text-base pb-2">{{ review.author }}</h3>
          </div>
          <p class="text-brand-black font-normal md:text-lg text-base flex-grow whitespace-pre-line">{{ review.content }}</p>
        </article>
      </template>
    </TransitionGroup>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';

// Import logos statically for better performance
import bambooLogo from '@/assets/images/bamboo.svg';
import sabiLogo from '@/assets/images/sabi.svg';
import casavaLogo from '@/assets/images/casava.svg';
import crowdLogo from '@/assets/images/crowd.svg';

// Cache logos for better performance
const logos = {
  bamboo: bambooLogo,
  sabi: sabiLogo,
  casava: casavaLogo,
  crowd: crowdLogo,
};

const current = ref(1);

// Add unique IDs to reviews for better key management
const clientReviews = [
  {
    id: 1,
    name: 'Richmond Bassey',
    title: 'Co-founder/ CEO Bamboo invest',
    logo: logos.bamboo,
    content:
      "I think CareerBuddy is the only recruitment company I've worked with that understands startup hiring and I have worked with many. You take action fast, understand the uncertainty, need for nimbleness and working in iterations throughout the whole process. You're mentally present and almost always help me clarify my implicit needs I haven't even identified.\n\nThe best part has always been the quality of people on the other side of the process.",
  },
  {
    id: 2,
    name: 'Anu Adasolom',
    title: 'Chief Executive Officer, Sabi',
    logo: logos.sabi,
    content:
      "CareerBuddy is the best kept secret in the world of startup recruiting without a doubt. We can't recommend them enough, we have tried a lot of recruiting partners even 'high profile' ones but with little or no success. CareerBuddy makes recruiting look easy. They understand our business like no other organization we work with and they continue to help us with some of our most critical roles.",
  },
  {
    id: 3,
    name: 'Segun Makinde',
    title: 'Chief Operating Officer (Casava)',
    logo: logos.casava,
    content:
      "CareerBuddy gets it. Hiring isn't a walk in the park but they make the recruitment process a lot less daunting. From how quickly they are able to source for candidate to ensuring the candidates are of high quality and a great fit for our company. They are great at what they do.",
  },
  {
    id: 4,
    name: 'Faisal Bahir',
    title: 'People Manager (Crowdforce)',
    logo: logos.crowd,
    content: 'CareerBuddy delivers 100% all of the time with quality pool of candidates and great turn around time making my job a lot easier.',
  },
];

const careerReviews = [
  {
    id: 1,
    content:
      "My experience with CareerBuddy was great. From the time they first reached out on LinkedIn, through the conversations with the hiring company and up to the time I received the offer. They were always checking in to know how it was going and helping to follow up with the Hiring company. They were also always available to answer any questions I had and pass it on where the didn't have the answers. I totally enjoyed working with them…it was easy and seamless.\n\nIf you need the right candidate, CareerBuddy is definitely your 'go-to' plug. Thanks guys you're amazing.",
    author: 'Temilolu (Oval finance)',
  },
  {
    id: 2,
    content:
      "Still buzzing from yesterday's amazing news! I can't thank you and the team enough for your incredible support throughout the process.\n\nSeriously, this has been the smoothest, most transparent recruitment experience I've had in ages. Your guidance and efficiency made everything so much easier. You guys are rockstars!\n\nThank you again from the bottom of my heart. You all went above and beyond, and I truly appreciate it.",
    author: 'Jeremiah (Remote Pass)',
  },
  {
    id: 3,
    content:
      'Thanks again for all your support throughout our recruitment process. Truly CareerBuddy is an integral part of my amazing role in RemotePass!',
    author: 'Riza Tavesora',
  },
  {
    id: 4,
    content:
      "I'm so excited to start this new chapter of my career and I am eager to contribute to the success of my team as HR manager. Thank you so much for providing such great assistance and guidance during the recruitment process. I really appreciate the experience.",
    author: 'Chaimae, Autochek',
  },
  {
    id: 5,
    content:
      "I appreciate the encouragement and reassurance as I am embark on this amazing journey at Shutlers. It is comforting to know that I have such a supporting team by my side. I'll definitely reach out if I need any guidance or clarity along the way. Looking forward to the growth ahead and making meaningful contributions to the team.",
    author: 'Temitayo (Shuttlers)',
  },
];

// Computed properties for active tab styles and current reviews
const indicatorPosition = computed(() => ({
  'left-0 w-[42%]': current.value === 1,
  'left-[48%] w-[52%]': current.value === 2
}));

const activeTabClasses = (tabNumber) => ({
  'text-[#161C2C] font-bold': current.value === tabNumber,
  'text-[#8F8F8F] hover:text-gray-600': current.value !== tabNumber,
});

const activeReviews = computed(() => (current.value === 1 ? clientReviews : careerReviews));

// Methods
// Animation handlers for better performance
const onBeforeEnter = (el) => {
  el.style.opacity = 0;
  el.style.transform = 'translateX(10px)';
};

const onEnter = (el, done) => {
  requestAnimationFrame(() => {
    el.style.transition = 'all 0.3s ease-out';
    el.style.opacity = 1;
    el.style.transform = 'translateX(0)';
    el.addEventListener('transitionend', done, { once: true });
  });
};

const onLeave = (el, done) => {
  requestAnimationFrame(() => {
    el.style.transition = 'all 0.3s ease-out';
    el.style.opacity = 0;
    el.style.transform = 'translateX(10px)';
    el.addEventListener('transitionend', done, { once: true });
  });
};

// Debounced tab switch for better performance
let switchTimeout;
const switchTab = (tabNumber) => {
  if (current.value === tabNumber || switchTimeout) return;

  clearTimeout(switchTimeout);
  switchTimeout = setTimeout(() => {
    current.value = tabNumber;
    switchTimeout = null;
  }, 50);
};
</script>

<style scoped>
/* Base styles */
.testimonial-card {
  @apply bg-[#FAFAFA] rounded-2xl border border-[#F0F0F0] p-6 h-full flex flex-col;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
}

/* Tab styles */
.reviews-container {
  @apply flex justify-center items-center my-8 md:my-12 w-full;
  contain: content;
}

.reviews-tabs {
  @apply bg-[#F0F0F0] rounded-[28px] flex items-center justify-center relative w-full max-w-[500px] mx-4 md:mx-0;
  contain: layout style;
}

.tab-button {
  @apply py-3.5 px-5 cursor-pointer z-10 relative flex-1 text-center;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  transition: color 0.3s ease-out;
}

.tab-text {
  @apply whitespace-nowrap block;
}

.tab-button:focus-visible {
  @apply outline-none ring-2 ring-offset-2 ring-brand-black;
}

.tab-indicator {
  @apply absolute bg-white rounded-[28px] h-[85%] top-[50%] transition-all duration-300 ease-out;
  transform: translateY(-50%) translateZ(0);
  will-change: transform, left, width;
}

/* Image styles */
.company-logo {
  @apply order-1 md:order-2 h-[80px] w-[80px] object-contain;
  transform: translateZ(0);
}

/* Grid layout */
.reviews-grid {
  @apply grid md:grid-cols-2 gap-8 md:gap-12;
  contain: content;
}

/* Optimize fixed elements */
.fixed-element {
  transform: translateZ(0);
  will-change: transform;
}
</style>
