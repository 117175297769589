<template>
  <Hero
    heroTitle="We help you find and <br class='hidden xl:inline-block' /> hire amazing employees."
    heroDesc="We're creating a smarter way to hire by blending the latest technology and a personal touch to help companies hire and manage the right talents."
    heroBtnText="Find your next Hire"
    heroBtnLink="/employer"
    heroBtnTwoText="Speak to Sales"
    heroBtnTwoLink="/contact"
  />

  <Customers heroImg="bigHero.svg" imageContainerClass="xl:-top20" class="" />

  <div class="flex justify-center bg-white px-[24px] md:px-[80px] py-8 md:py-[48px]">
    <div class="w-full md:w[72vw]">
      <h2 class="text-center mb-3 text-[32px] md:text-[44px] text-brand-black leading-[110%] font-normal helvetica tracking-[-4%]">
        Why Hiring Teams love CareerBuddy
      </h2>
      <div class="md:gap-x-20 flex justify-center mb-[34px] md:flex-row gap-y-6 flex-col items-center">
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal">
            <count-up :startVal="0" :endVal="90" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="text-[15px] text-center leading-6 hel">Candidate response rate</small>
        </div>
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal">
            <count-up :startVal="0" :endVal="60" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="text-[15px] text-center leading-6">Interview acceptance rate</small>
        </div>
        <div>
          <p class="text-[100px] md:text-[86px] text-brand-black leading-[110%] font-normal text-center">
            <count-up :startVal="0" :endVal="85" :duration="10" :options="options" :delay="50" loop></count-up>
          </p>
          <small class="md:text-[15px] text-center leading-6">Candidates hired stay longer than 2 years</small>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white md:px-[80px] py-8 md:py-[60px]">
    <div class="flex items-stretch flex-col md:flex-row justify-center gap-8">
      <div class="md:px-[60px] px-[24px] md:py-20 py-6 md:w-[632px] flex flex-col rounded-2xl shadow-sm">
        <div class="space-y-2 mb-16">
          <p class="text-black font-medium text-lg">Expanding your team?</p>
          <h2 class="text-[20px] md:text-[32px] text-[#0F172A] font-semibold leading-tight">Why hiring teams love us!</h2>
        </div>
        <div class="flex-grow space-y-12">
          <div class="flex md:items-start gap-6">
            <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6" />
            <p class="text-[#334155] md:text-lg text-sm">Connect faster with highly qualified professionals who succeeded in previous roles.</p>
          </div>
          <div class="flex md:items-start gap-6">
            <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6" />
            <p class="text-[#334155] md:text-lg text-sm">Access to quality profiles with a unique blend of skills, experience, and dedication.</p>
          </div>
          <div class="flex md:items-start gap-6">
            <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6" />
            <p class="text-[#334155] md:text-lg text-sm">Receive top-tier candidate profiles under four days.</p>
          </div>
          <div class="flex md:items-start gap-6">
            <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6" />
            <p class="text-[#334155] md:text-lg text-sm">Advice on roles, profiles and scaling teams.</p>
          </div>
          <div class="flex md:items-start gap-6">
            <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6" />
            <p class="text-[#334155] md:text-lg text-sm">Consistent support and advice to suit your hiring needs</p>
          </div>
        </div>
        <div class="mt-16 gap-4 flex-col flex md:flex-row text-center">
          <a
            href="/employer"
            class="px-6 py-3.5 bg-white inline-block rounded-xl text-brand-black font-bold text-base border-2 border-[#1A1A1A] hover:bg-gray-50 transition-colors duration-300"
            >Learn more</a
          >
          <a
            href="https://employer.thecareerbuddy.com/login"
            class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white font-bold text-base hover:bg-black transition-colors duration-300"
            >Sign Up</a
          >
        </div>
      </div>

      <div
        class="relative md:px-[60px] px-[24px] md:py-20 py-6 md:w-[632px] flex flex-col rounded-2xl shadow-lg overflow-hidden"
        style="background: linear-gradient(180deg, #1a1a1a 0%, #2a2a2a 100%)"
      >
        <div class="relative">
          <div class="space-y-2 mb-16">
            <p class="text-brand-spot font-medium text-lg">Looking for a job?</p>
            <h2 class="text-[20px] md:text-[32px] text-white font-semibold leading-tight">Find out why job seekers love us!</h2>
          </div>
          <div class="flex-grow space-y-12">
            <div class="flex md:items-start gap-6">
              <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6 invert" />
              <p class="text-gray-300 md:text-lg text-sm">Rare career opportunities with exceptional companies</p>
            </div>
            <div class="flex md:items-start gap-6">
              <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6 invert" />
              <p class="text-gray-300 md:text-lg text-sm">
                Absolute confidentiality. We don't leave you hanging. We update you on the hiring process.
              </p>
            </div>
            <div class="flex md:items-start gap-6">
              <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6 invert" />
              <p class="text-gray-300 md:text-lg text-sm">Connect directly with Founders from seed to global tech giants</p>
            </div>
            <div class="flex md:items-start gap-6">
              <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6 invert" />
              <p class="text-gray-300 md:text-lg text-sm">Work with companies who offer attractive compensation</p>
            </div>
            <div class="flex md:items-start gap-6">
              <img :src="require(`@/assets/icons/list.svg`)" alt="" class="w-6 h-6 invert" />
              <p class="text-gray-300 md:text-lg text-sm">Insightful career resources to take you from average to elite</p>
            </div>
          </div>
          <div class="mt-16 gap-4 flex-col flex md:flex-row text-center">
            <a
              href="/talents"
              class="px-6 py-3.5 bg-transparent inline-block rounded-xl text-white font-bold text-base border-2 border-white hover:bg-white/10 transition-colors duration-300"
              >Learn more</a
            >
            <a
              href="https://candidate.thecareerbuddy.com/login"
              class="px-6 py-3.5 bg-brand-spot inline-block rounded-xl text-white font-bold text-base hover:bg-brand-spot/90 transition-colors duration-300"
              >Sign Up</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white md:px-0 p-6 md:py-[60px] md:w-[82vw] mx-auto">
    <small class="text-lg text-[#485467] mb-4">Companies and Hiring Teams</small>
    <p class="mb-16 md:text-[40px] text-[32px] text-[#161C2C] lg:w-[66%]">
      Our Platform lets you connect to candidates who  are qualified by the CareerBuddy talent team and ready to interview so you’ll spend more time
      meeting candidates, not trying to find them.
    </p>
    <Hiring :details="employerDetails" />
  </div>

  <Steps />

  <div class="bg-white md:px-0 p-6 md:py-[60px] md:w-[82vw] mx-auto">
    <small class="text-lg text-[#485467]">For Candidates</small>
    <p class="mb-16 md:text-[40px] text-[32px] text-[#161C2C] lg:w-[66%]">
      Companies of various sizes and industries turn to CareerBuddy to fill their open roles. We match your preferences and qualifications with
      employers ready to fill open positions.
    </p>

    <Hiring :details="talentDetails" reverse />
  </div>

  <div class="md:px-20 lg:py-[100px] p-6 md:flex justify-evenly mb-16">
    <testimonial />
  </div>

  <div class="flex bg-[#FEFAF5] px-[24px] md:px-0 py-8 md:py-[60px] relative">
    <div class="w-full md:w-[82vw] mx-auto">
      <h2 class="text-center mb-8 text-[32px] md:text-[44px] text-brand-black leading-[110%] font-normal helvetica tracking-[-4%]">
        Latest Job Opportunities
      </h2>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
        <job-card v-for="job in recentJobs" :key="job.uuid" :job="job" />
      </div>

      <div class="text-center mt-8">
        <router-link to="/jobs" class="px-8 py-4 bg-brand-black rounded-xl text-white inline-block">View All Jobs</router-link>
      </div>
    </div>
  </div>

  <div class="bg-[#F8FAFC] md:px-[80px] py-8 md:py-[60px]">
    <p class="md:text-center text-[32px] md:text-[44px] text-[#1A1A1A] md:leading-[56px] font-semibold mb-12">Latest from our blog</p>
    <div class="grid md:grid-cols-3 gap-8 px-6 md:px-0 w-full md:w-[82vw] mx-auto">
      <template v-for="(blog, index) in blogPosts" :key="index">
        <BlogCard :blog="blog" />
      </template>
    </div>
  </div>

  <div
    class="flex justify-center bg-cover bg-no-repeat md:px-20 px-6 py-[60px]"
    :style="`background-image: url(${require('@/assets/images/globalBg.svg')})`"
  >
    <div class="w-full lg:w-[41vw]">
      <img src="@/assets/images/global.svg" alt="" class="mx-auto w-1/2 md" />
      <p class="md:text-[44px] text-[32px] text-brand-black py-6 text-center">Your #1 Global Partner for hiring</p>
      <p class="text-black text-bold text-base pb-6 text-center">
        At CareerBuddy, we're not just a recruitment agency, we're your best choice for discovering top talent worldwide. Our track record of linking
        global companies with premium candidates has made us a top choice for hiring success
      </p>
      <div class="flex flex-col md:flex-row md:items-center gap-4 md:justify-center">
        <a href="/employer" class="px-6 py-3.5 bg-brand-black inline-block rounded-xl text-white text-center">Start Hiring Now</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import Hero from '@/components/partials/Hero.vue';
import Customers from '@/components/partials/Customers.vue';
import Testimonial from '@/components/partials/Testimonial.vue';
import Hiring from '@/components/partials/Hiring.vue';
import Steps from '@/components/partials/Steps.vue';
import { useMeta } from '@/utils/meta';
import { onMounted, ref, onBeforeUnmount } from 'vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CountUp from 'vue-countup-v3';
import JobCard from '@/components/jobs/JobCard.vue';
import { useStore } from 'vuex';
import { getBlogPosts } from '@/services/contentful';
import BlogCard from '@/components/partials/BlogCard.vue';

const store = useStore();
const recentJobs = ref([]);
const blogPosts = ref([]);
const isLoadingBlogs = ref(false);

const employerDetails = ref([
  {
    id: 1,
    img: 'hiringTeam.svg',
    text: 'Job Portal',
    link: 'https://employer.thecareerbuddy.com/login',
    description: 'Post your open jobs and let candidates who are actively looking apply directly',
    btnText: 'Sign up as an Employer',
  },
  {
    id: 2,
    img: 'pool.svg',
    text: 'Talent Pool',
    link: 'https://employer.thecareerbuddy.com/login',
    description:
      'Cut through the noise to find the best talent as you build your candidate pipeline with ease and find pre-vetted tech candidates to quickly send interview requests.',
    btnText: 'Sign up as an Employer',
  },
  {
    id: 3,
    img: 'companyJob.svg',
    text: 'Company Job Page',
    link: 'https://employer.thecareerbuddy.com/login',
    description:
      'Set up your company profile page and add as much information you can about your company. Let candidates know about your work culture before they apply.',
    btnText: 'Sign up as an Employer',
  },
  {
    id: 4,
    img: 'messaging.svg',
    text: 'Messaging',
    link: 'https://employer.thecareerbuddy.com/login',
    description: 'Message candidates as much as you need. Reach out and pitch your opportunity to candidates or respond to applications.',
    btnText: 'Sign up as an Employer',
  },
]);

const talentDetails = ref([
  {
    img: 'hiringTeam.svg',
    text: 'Job Discovery',
    link: 'https://candidate.thecareerbuddy.com/login',
    description:
      'Whether you’re remote, hybrid, or fully in-office, CareerBuddy will connect you directly with hiring managers at high growth companies.',
    id: 1,
    btnText: 'Sign up as a talent',
  },
  {
    img: 'pool.svg',
    text: 'Refer and earn',
    link: '/referral',
    description: 'Know a friend or colleague who’s actively looking and would be a great fit for one of our open roles? Refer and earn',
    id: 2,
    btnText: 'Start Referring',
  },
  {
    img: 'companyJob.svg',
    text: 'CV Writing',
    link: '/cv',
    description: 'Put your best foot forward. Let our team upgrade your CV so you can land your dream job faster',
    id: 3,
    btnText: 'Upgrade my CV',
  },
]);

// Cleanup function to prevent memory leaks
onBeforeUnmount(() => {
  if (ScrollTrigger) {
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  }
});

useMeta();
onMounted(() => {
  gsap.to('.events', { scrollTrigger: '.events', x: 100, duration: 3 });
  gsap.registerPlugin(ScrollTrigger);
});

onMounted(async () => {
  // Initialize GSAP
  gsap.registerPlugin(ScrollTrigger);
  gsap.to('.events', { scrollTrigger: '.events', x: 100, duration: 3 });

  // Fetch jobs and blog posts in parallel
  try {
    isLoadingBlogs.value = true;
    const [jobsResult, posts] = await Promise.all([store.dispatch('global/getAllJobs'), getBlogPosts()]);

    // Set jobs
    const allJobs = store.getters['global/getAllJobs'];
    recentJobs.value = allJobs.slice(0, 3);

    // Set blog posts
    blogPosts.value = posts.filter((post) => post.isFeatured).slice(0, 3);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    isLoadingBlogs.value = false;
  }
});

const options = ref({
  useEasing: true,
  useGrouping: true,
  suffix: '%',
});
</script>
